<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddOrderDiscount"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('ORDER.DISCOUNT.ADD.HEADER.TEXT')}}</p>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('ORDER.DISCOUNT.ADD.LABEL.DESCRIPTION')"  class="float-left mr-2 inputx" :placeholder="$t('ORDER.DISCOUNT.ADD.LABEL.PLACEHOLDER.DESCRIPTION')" v-model="data.description"  :danger="description_fail" val-icon-danger="clear"/>
            <div class="float-left">
              <vs-select v-model="data.discounttype" class="ml-2" :label="$t('ORDER.DISCOUNT.ADD.LABEL.TYPE')">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in discounttypes" />
              </vs-select>
            </div>
          </div>
          
          <vs-divider position="center">{{$t('ORDER.DISCOUNT.ADD.LABEL.VALID')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-3">
            <div  class="float-left mr-2">
              <vs-input-number v-model="data.valid_days" min="0" :label="$t('ORDER.DISCOUNT.ADD.LABEL.VALID_DAYS')" class="inputx" />
            </div>
              <div  class="float-left mt-2">
                <vs-checkbox v-model="data.ignore_valid_days">{{$t('ORDER.DISCOUNT.ADD.LABEL.IGNORE_VALID_DAYS')}}</vs-checkbox>
              </div>
              <div  class="float-left mt-2">
                <vs-checkbox v-model="data.printable">{{$t('ORDER.DISCOUNT.ADD.LABEL.PRINTABLE')}}</vs-checkbox>
              </div>
          </div>  
          <vs-divider position="center">{{$t('ORDER.DISCOUNT.ADD.LABEL.SUM')}}</vs-divider>
          <div class="">  
            <ValueProzent ref="valueProzentComp" class="" 
              :CurrencyKuerzel="CurrencyKuerzel"
              :LanguageFk="LanguageFk" 
              :FullAmount="OrderSum" 
              v-on:ValuesChanged="ValuesChanged" 
              :InitProz="data.discount_proz" 
              :InitAmount="data.discount_amount">
            </ValueProzent>
          </div>  
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import ValueProzent from '../ValueProzent.vue';

export default {
  name: "AddOrderDiscountDlg",
  props:['LanguageFk','CurrencyKuerzel','OrderSum'],
  components:{
    ValueProzent
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('ORDER.DISCOUNT.ADD.TITLE'),
      description_fail:false,
      description_ok:false,
      discount_amount_fail:false,
      discount_amount_ok:false,
      discount_proz_fail:false,
      discount_proz_ok:false,
      data:this.GetEmptyData()
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(order_fk){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('ORDER.DISCOUNT.ADD.TITLE');
        this.data.order_fk = order_fk;
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.popupTitle = this.$t('ORDER.DISCOUNT.ADD.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));
          this.dlgActive=true;
        }
      },
      accepted(){

          this.$store.dispatch('accounting/SaveOrderDiscount', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.DISCOUNT.ADD.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('ORDER.DISCOUNT.ADD.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.DISCOUNT.ADD.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
          description:"",
          order_fk:-1,
          discounttype:0,
          discount_amount:0,
          discount_proz:0,
          valid_days:0,
          ignore_valid_days:false,
          ignoredCauseInvalid:false,
          used:false
        };
      },
      ValuesChanged(data){
        this.data.discount_amount = data.amount;
        this.data.discount_proz = data.proz;
      }
  },
  computed:{
    formValid(){
      return this.description_ok && this.discount_amount_ok;
    },
    description(){
      return this.data.description;
    },
    discount_amount(){
      return this.data.discount_amount;
    },
    discount_proz(){
      return this.data.discount_proz;
    },
    discounttypes()
    {
      return this.GetDiscountTypesForSelect(false);
    }
  },
  watch:{
    description(value)
    {
       this.description_fail = value.length == 0;
       this.description_ok = !this.description_fail;
    },
    discount_amount(value)
    {
      this.discount_amount_fail = value <= 0;
      this.discount_amount_ok = !this.discount_amount_fail;
    },
    discount_proz(value)
    {
      this.discount_proz_fail = value <= 0;
      this.discount_proz_ok = !this.discount_proz_fail;
    }
  }
};
</script>
<style>
div#promptAddOrderDiscount > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddOrderDiscount > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>