<template>
  <div>
    <vs-dropdown :disabled="isDisabled">
      <vs-chip color="primary" :closable="!isDisabled" close-icon="expand_more">
        {{GetShipmentStatusText(status)}}
      </vs-chip>
      <vs-dropdown-menu >
        <template v-for="(mem, index) in member" >
          <vs-dropdown-item :key="'shipmentStatusDropItem_'+index" @click="status = mem.value">
            {{mem.text}}
          </vs-dropdown-item>
        </template>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>
<script>
import enumHelper from '../../helper/enumHelper';
export default {
  name: "ShipmentStatus",
  props:['value','disabled','OwnerFk'],
   data: function (){
     return {
       status: this.value,
       isDisabled:this.disabled
     }
  },
  computed:{
    member()
    {
      return this.GetShipmentStatusForDropDown();
    }
  },
  methods:{
    ...enumHelper,
    Disable(val){
      this.isDisabled = val;
    }
  },
  watch:{
    value(newVal){
      this.status = newVal;
    },
    status(value)
    {
        this.$emit('input', value);
        this.$emit('StatusChanged',value,this.OwnerFk);
    },
  }
};
</script>