<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.OK')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddRemoveInvoice"
      :is-valid="formValid"
      buttons-hidden>
       <div>
        <NotFoundCard v-show="items.length == 0" :Text="$t('ORDER.ADD_INVOICE.NO_INVOICE_FOUND')"></NotFoundCard>
        <vs-collapse accordion >
          <template v-for="(item, idx) in items">
            <vs-collapse-item :key="'order'+idx" :open="idx == 0" >
              <div slot="header">
                {{item.order.bez}}
              </div>
              <vs-table
                :data="item.invoices"
                :noDataText="$t('ORDER.MEM.NO_DATA')"
                stripe
                max-items="5" 
                pagination
                hoverFlat>

                <template slot="thead">                               
                      <vs-th sort-key="bez">
                        {{$t('INVOICE.TABLE.COL1')}}
                      </vs-th>
                      <vs-th sort-key="invoice_nr">
                        {{$t('INVOICE.TABLE.COL2')}}
                      </vs-th> 
                      <vs-th sort-key="type">
                        {{$t('INVOICE.TABLE.COL7')}}
                      </vs-th>  
                      <vs-th sort-key="state">
                        {{$t('INVOICE.TABLE.COL5')}}
                      </vs-th>  
                      <vs-th sort-key="state">
                        {{$t('INVOICE.TABLE.COL8')}}
                      </vs-th> 
                      <vs-th>
                        
                      </vs-th>                                                                                                                                                                                               
                </template>

                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">                    
                      <vs-td :data="tr.bez">
                        {{tr.bez}}
                      </vs-td>      
                      <vs-td :data="tr.invoice_nr">
                        {{tr.invoice_nr}}
                      </vs-td>   
                      <vs-td :data="tr.type">
                          {{GetInvoiceTypeText(tr.type)}}
                      </vs-td>
                      <vs-td :data="tr.state">
                        <vs-chip :color="GetCommonStateColor(tr.state)">
                          {{GetCommonStateText(tr.state)}}</vs-chip>
                      </vs-td> 
                      <vs-td :data="tr.amount">
                        {{GetInvoiceSumOffen(tr)}} / <h4>{{GetInvoiceSum(tr)}}</h4>
                      </vs-td>
                      <vs-td>
                        <vs-button @click="AddInvoiceToOrder(tr)" class="ml-2" size="small" color="primary" type="filled" icon="add"></vs-button>  
                      </vs-td>                                                                                                                                                                                                     
                    </vs-tr>
                </template>
              </vs-table>
            </vs-collapse-item>
          </template>
        </vs-collapse>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import NotFoundCard from '../NotFoundCard.vue';

export default {
  name: "AddRemoveInvoiceDlg",
  props:['InitData'],
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('ORDER.ADD_INVOICE.TITLE'),
      items:[],
      debitorContactFk:0,
      order_fk:0
     }
  },
  components:{
    NotFoundCard
  },
  mounted () {
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      
      ShowDlg(debitorcontactfk,order_fk){
        this.ClearForm();
        this.debitorContactFk = debitorcontactfk;
        this.order_fk = order_fk;
        var data = {debitorcontact_fk:this.debitorContactFk,order_fk:this.order_fk};
        this.$store.dispatch('accounting/getInvoicesForNotFinishedOrders',data);
        this.dlgActive=true;
      },

      closed(){
        this.ClearForm();
      },
      
      AddInvoiceToOrder(invoice){

          var data = {
            order_fk:this.order_fk,
            invoice_fk:invoice.id,
            remove:false
          };

          var name = this.order_nr;
          this.$store.dispatch('accounting/addRemoveInvoiceToOrder', data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.ADD_INVOICE.SUCCESS.SAVE').replace("*1*",name)});  
                this.$emit('Success',response.id);
                this.dlgActive=false;
            }
            else
            {
              var text = this.$t('ORDER.ADD_INVOICE.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              var text = this.$t('ORDER.ADD_INVOICE.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 

      },
      GetCurrencyString(amount,language_fk,currency_kuerzel){

        var retVal = "";
        if(currency_kuerzel.length > 0 && language_fk != 0)
        {
          retVal = this.DecimalToString(amount,currency_kuerzel,this.GetLocalFormatOfLanguage(language_fk));  
        }
        return retVal;
      },
      GetInvoiceSum(invoice)
      {
        var sum = 0;
        var txt = "";
        if(this.IsNotNull(invoice))
        {
          sum = invoice.amount;
          txt = this.GetCurrencyString(sum,invoice.language_fk,invoice.currency_kuerzel);
        }
        return txt;
      },
      GetInvoiceSumOffen(invoice)
      {
        var txt = "";
        if(this.IsNotNull(invoice))
        {
          txt = this.GetCurrencyString(invoice.amount_bez,invoice.language_fk,invoice.currency_kuerzel);
        }
        return txt;
      },
  },
  computed:{
    formValid(){
      return false;
    },
    invoicesInStore(){
        var data = [];
        if(this.$store.state.accounting.invoicesForNotFinishedOrders.data != null)
        {
            data = this.$store.state.accounting.invoicesForNotFinishedOrders.data
        }
        return data;
    }
  },
  watch:{
    invoicesInStore(value)
    {
        this.items = value;
    },
  }
};
</script>
<style>
div#promptAddRemoveInvoice > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddRemoveInvoice > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>