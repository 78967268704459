<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.OK')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddInvoice"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('INVOICE.ADD_INVOICE.HEADER.TEXT')}}</p>
          <div class="default-input d-flex align-items-center">{{$t('INVOICE.ADD_INVOICE.INVOICE_NUMBERGROUP')}} 
            <vs-select :disabled="invoice_nr.length > 0" v-model="numbergroup_fk" class="ml-2" :danger="numbergroup_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mb-3 mt-2">
            <vs-input ref="bez" :label="$t('INVOICE.ADD_INVOICE.LABEL.BEZ')"  class="float-left mr-2 inputx" :placeholder="$t('INVOICE.ADD_INVOICE.LABEL.PLACEHOLDER.BEZ')"  v-model="bez" :danger="bez_fail" val-icon-danger="clear"/>
          </div>
          <div class="default-input d-flex align-items-center mb-3 mt-2">
            <DatePicker ref="pickerDeliveryDate" Type="date" class="float-left" :Label="$t('INVOICE.ADD_INVOICE.DELIVERY_DATE')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('INVOICE.ADD_INVOICE.TITLE.DELIVERY_DATE')" v-model="deliverydate"></DatePicker>
            <vs-checkbox v-model="useDeliveryText">{{$t('INVOICE.ADD_INVOICE.LABEL.USE_DELIVERY_TEXT')}}</vs-checkbox>
          </div>
          <div class="default-input d-flex align-items-center mb-3 mt-2">
            <vs-input :disabled="!useDeliveryText" :label="$t('INVOICE.ADD_INVOICE.LABEL.DELIVERY_TEXT')"  class="float-left mr-2 inputx" :placeholder="$t('INVOICE.ADD_INVOICE.LABEL.PLACEHOLDER.DELIVERY_TEXT')"  v-model="deliveryText"  :danger="deliveryText_fail" val-icon-danger="clear"/>
          </div>
          <div v-show="isAnzahlung">
            <vs-divider position="center" >{{$t('INVOICE.ADD_INVOICE.ANZAHLUNG')}}</vs-divider>
            <h4>{{$t('INVOICE.ADD_INVOICE.ANZAHLUNGSBETRAG')}} {{GetCurrencyString(sumOrder)}}</h4>
            
            <ValueProzent  class="mt-3" :CurrencyKuerzel="currency_kuerzel" :LanguageFk="language_fk" :FullAmount="sumOrder" v-on:ValuesChanged="ValuesChanged" :InitAmount="sumOrder * 0.1" :InitProz="10"></ValueProzent>

          </div>
        </div>
       </div>
      </vs-prompt>
      <vs-prompt
        :active.sync="dlgActiveSendMail"
        :title="$t('INVOICE.ADD_INVOICE.NO_AB_FOUND').replace('*1*',docnr)"
        :accept-text="$t('INVOICE.ADD_INVOICE.CREATE_INVOICE_FORCE')"
        :cancel-text="$t('COM.CANCEL')"
        @accept="forceCreateInvoice"
        id="promptAddInvoiceSendAbMail">
        <div>
            <p>{{$t('INVOICE.ADD_INVOICE.AB_NOT_SENT_OPEN_MAIL_DLG')}}</p>
            <SendDebitorDocuemntMailBtn class="mb-3" :doc_nr="docnr" :doc_id="confirmation_fk" :doc_type="docTypeAb" :ButtonText="$t('INVOICE.ADD_INVOICE.SEND_AB_MAIL')" :WithText="true"></SendDebitorDocuemntMailBtn>
        </div>
      </vs-prompt>
      <vs-prompt
        :active.sync="dlgActiveSendMailInfo"
        :title="$t('INVOICE.ADD_INVOICE.NO_AB_FOUND').replace('*1*',docnr)"
        :accept-text="$t('INVOICE.ADD_INVOICE.CREATE_INVOICE_FORCE')"
        :cancel-text="$t('COM.CANCEL')"
        @accept="forceCreateInvoice"
        id="promptAddInvoiceSendAbMailInfo">
        <div>
            <p>{{this.textDlgMailSent}}</p>
        </div>
      </vs-prompt>      
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import {numbergroup_Types} from '../../../helper/enumHelper';
import {invoiceTypes} from "../../../helper/enumHelper";
import ValueProzent from '../ValueProzent.vue';
import DatePicker from "../DatePicker.vue";
import {documentType} from "../../../helper/enumHelper";
import SendDebitorDocuemntMailBtn from "./SendDebitorDocuemntMailBtn.vue";

export default {
  name: "AddInvoiceDlg",
  props:['InitData'],
  components:{
    ValueProzent,
    DatePicker,
    SendDebitorDocuemntMailBtn
  },
  data: function (){
     return {
       dlgActive:false,
       popupTitle:this.$t('INVOICE.ADD_INVOICE.TITLE'),
       invoice_nr:"",
       numbergroup_fk:-1,
       numbergroup_fk_fail:false,
       numbergroup_fk_ok:false,
       bez:"",
       bez_fail:false,
       bez_ok:false,
       order_fk:0,
       order_nr:"",
       ordermems:[],
       type:invoiceTypes.GESAMT,
       anzahlung_proz:10,
       isAnzahlung:false,
       language_fk:0,
       currency_kuerzel:"",
       numbergroups:[],
       anzahlungsbetrag:0,
       useProz:true,
       deliverydate:null,
       useDeliveryText:false,
       deliveryText:"",
       deliveryText_fail:false,
       deliveryText_ok:true,
       defaultNrGrpFk:-1,
       dlgActiveSendMail:false,
       confirmation_fk:0,
       docTypeAb:documentType.AB,
       docnr:"",
       dlgActiveSendMailInfo:false,
       textDlgMailSent:"",
       forceInvoice:false
     }
  }, 
  mounted () {
    this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      
      ShowDlg(order_fk,order_nr,ordermems,type,language_fk,currency_kuerzel , deliverydate_from_offer, deliveryText_from_offer, useDeliveryText_from_offer){
        this.ClearForm();

        if(this.defaultNrGrpFk != -1)
        {
          this.numbergroup_fk = this.defaultNrGrpFk;
        }
        
        this.bez = this.$t('INVOICE.ADD_INVOICE.INVOICE_FOR_ORDER') + ' ' + order_nr;
        this.order_fk = order_fk;
        this.order_nr = order_nr;
        this.ordermems = ordermems;
        this.type = type;
        this.language_fk = language_fk;
        this.currency_kuerzel = currency_kuerzel;
        this.anzahlungsbetrag = this.sumOrder * this.anzahlung_proz / 100;
        this.dlgActive=true;
        this.deliveryText = deliveryText_from_offer;
        this.useDeliveryText = useDeliveryText_from_offer;
        this.deliverydate = deliverydate_from_offer;
      },
      closed(){
        this.ClearForm();
      },
      forceCreateInvoice()
      {
        this.forceInvoice = true;
        this.accepted();
      },
      accepted(){

          var invoice = {
            bez:this.bez,
            numbergroup_fk:this.numbergroup_fk,
            order_fk:this.order_fk,
            type:this.type,
            anzahlung_proz:this.anzahlung_proz,
            member:this.ordermems,
            anzahlung_amount:this.anzahlungsbetrag,
            takeProz:this.useProz,
            deliverydate: this.deliverydate,
            deliveryText: this.deliveryText,
            useDeliveryText: this.useDeliveryText,
            force:this.forceInvoice
          };

          var name = this.order_nr;
          this.$store.dispatch('accounting/createInvoiceForOrder', invoice)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('INVOICE.ADD_INVOICE.SUCCESS.SAVE').replace("*1*",name)});  
                this.$emit('Success',response.id);
                this.ClearForm();
            }
            else
            {
              this.confirmation_fk = response.id;
              this.docnr = response.txt1;
              switch(response.text)
              {
                case "NO_AB_SENT_NO_AB_FOUND":
                  {
                    this.textDlgMailSent = this.$t("INVOICE.ADD_INVOICE.NO_AB_SENT_NO_AB_FOUND");
                    this.dlgActiveSendMailInfo = true;
                    break;
                  }
                case "NO_AB_SENT":
                  {
                    this.textDlgMailSent = this.$t("INVOICE.ADD_INVOICE.NO_AB_SENT");
                    this.dlgActiveSendMailInfo = true;
                    break;
                  } 
                case "NO_AB_SENT_DOC_EXIST":
                  {
                    this.dlgActiveSendMail = true;
                    break;
                  }                                    
                default:
                  {                  
                    var text = this.$t('INVOICE.ADD_INVOICE.WARNING.SAVE').replace("*1*",name);
                    text = text.replace("*2*",this.$t(response.text));
                    this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
                    break;
                  }
              }
              
            }
            
          }, 
          error =>{
              var text = this.$t('INVOICE.ADD_INVOICE.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.ClearForm();
          });

          
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.invoice_nr = "";
        this.numbergroup_fk = -1;
        this.bez = "";
        this.numbergroup_fk_fail = false;
        this.numbergroup_fk_ok = false;
        this.bez_fail = false;
        this.bez_ok = false;
        this.order_fk = 0;
        this.order_nr ="";
        this.ordermems = [];
        this.type = invoiceTypes.GESAMT;
        this.anzahlung_proz = 10;
        this.isAnzahlung = false;
        this.language_fk = 0;
        this.currency_kuerzel = "";
        this.anzahlungsbetrag = 0;
        this.useProz = false;
        this.useDeliveryText = false;
        this.deliveryText = "";
        this.deliverydate = null;
        this.deliveryText_fail = false,
        this.deliveryText_ok = true,
        this.forceInvoice = false;
      },
      GetCurrencyString(amount){

        var retVal = "";
        if(this.currency_kuerzel.length > 0 && this.language_fk != 0)
        {
          retVal = this.DecimalToString(amount,this.currency_kuerzel,this.GetLocalFormatOfLanguage(this.language_fk));  
        }
        return retVal;
      },
      ValuesChanged(data)
      {
        this.anzahlungsbetrag = data.amount;
        this.anzahlung_proz = data.proz;
      }
  },
  computed:{
    formValid(){
      return this.bez_ok && this.numbergroup_fk_ok && this.deliveryText_ok;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.RECHNUNG);
    },
    sumOrder(){
      var retVal = 0;

      if(this.IsNotNull(this.ordermems))
      {
        this.ordermems.forEach(mem => {
          retVal += mem.brutto_sum;
        });
        
      }
      return retVal;
    }
  },
  watch:{
    numbergroupsForSelect(value)
    {
        this.numbergroups = value.numbergroups;
        this.defaultNrGrpFk = value.default;
    },
    bez(value)
    {
      this.bez_fail = value.length == 0;
      this.bez_ok = !this.bez_fail;
    },
    numbergroup_fk(value){
      this.numbergroup_fk_fail = value <= 0;
      this.numbergroup_fk_ok = !this.numbergroup_fk_fail;
    },
    type(value)
    {
      this.isAnzahlung = value == invoiceTypes.ANZAHLUNG;
    },
    deliveryText(value)
    {
      if(this.useDeliveryText == true)
      {
        this.deliveryText_fail = value.length == 0;
        this.deliveryText_ok = !this.deliveryText_fail;
      }
    },
    useDeliveryText(newVal)
    {
      if(newVal == false)
      {
          this.deliveryText_fail = false;
          this.deliveryText_ok = true;
      }
      else
      {
        this.deliveryText_fail = this.deliveryText.length == 0;
        this.deliveryText_ok = !this.deliveryText_fail;
      }
    }
  }
};
</script>
<style>
div#promptAddInvoice > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddInvoice > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>